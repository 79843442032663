import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HereMap from './HereMap'; // Make sure this component is properly implemented
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import loadingAnimation from './assets/loading.json'; // Adjust the path if necessary
import Header from './components/Header';

function App() {
  const { t, i18n } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [language, setLanguage] = useState('en'); // Default language
  const [radius, setRadius] = useState(5);
  const [unit, setUnit] = useState('miles');
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [entryPhoto, setEntryPhoto] = useState(null);
  const [backstockPhoto, setBackstockPhoto] = useState(null);
  const [exitPhoto, setExitPhoto] = useState(null);
  const [comments, setComments] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [address, setAddress] = useState('');
  const [mapCenter, setMapCenter] = useState(null);
  const [zoom, setZoom] = useState(12);
  const [selectedLocation, setSelectedLocation] = useState('');

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    if (currentStep === 2) {
      setLoading(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCoordinates({ latitude, longitude });
            setLoading(false);
            setCurrentStep(2.2);
          },
          (error) => {
            setLoading(false);
            console.error('Geolocation error:', error);
            setCurrentStep(2.1);
          }
        );
      } else {
        setLoading(false);
        alert('Geolocation is not supported by your browser.');
        setCurrentStep(2.1);
      }
    }
  }, [currentStep]);

  const handleLanguageSelect = (lang) => {
    setLanguage(lang);
    setCurrentStep(2);
  };

  const renderLoading = () => (
    <div style={{
      position: 'fixed', // Use 'fixed' to cover the entire screen
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
      zIndex: 1000, // Ensure it's above other elements
    }}>
      <Lottie animationData={loadingAnimation} style={{ width: '150px', height: '150px' }} />
    </div>
  );  

  const handleGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude });
          setCurrentStep(2.2); // Move to next step after getting coordinates
        },
        (error) => {
          console.error('Geolocation error:', error);
          setCurrentStep(2.1); // Move to manual address input step if geolocation fails
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
      setCurrentStep(2.1); // Geolocation not supported
    }
  };

  const handleAddressSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://www.mapquestapi.com/geocoding/v1/address', {
        params: {
          key: 'VinCbM6qNA9i7iSZsS0ITZrrKO40WXRh',
          location: address,
        },
      });
      const { lat, lng } = response.data.results[0].locations[0].latLng;
      setCoordinates({ latitude: lat, longitude: lng });
      setCurrentStep(2.2);
    } catch (error) {
      console.error('Error fetching location from address:', error);
      alert('Failed to fetch location from address.');
    } finally {
      setLoading(false);
    }
  };

  const handleHomeClick = () => {
    setCurrentStep(1);
  };

  // Function to navigate to the previous step
  const handleBack = () => {
    switch (currentStep) {
      case 2:
      case 2.1:
      case 2.2:
        setCurrentStep(1);
        break;
      case 3:
        setCurrentStep(2.2); // Assuming you want to go back to the radius selection
        break;
      case 4:
        setCurrentStep(3);
        break;
      // Add more cases if there are more steps
      default:
        break;
    }
  };

  // Function to handle the lookup when button is clicked
  const handleLookup = () => {
    if (coordinates) {
      performLocationLookup(coordinates.latitude, coordinates.longitude);
    } else {
      alert('Location not set. Please go back and set your location.');
    }
  };

  const performLocationLookup = async (latitude, longitude) => {
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_LOCATION_API_URL, {
        latitude,
        longitude,
        radius,
        unit,
      });
      setLocations(response.data);
      setMapCenter({ lat: latitude, lng: longitude });
      setZoom(12);
      setCurrentStep(3); // Move to locations display step
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Check if a store is selected
    if (!selectedLocation) {
      alert('Please select a store.');
      return;
    }

    setLoading(true); // Start loading
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('serial_number', serialNumber);
    formData.append('store', selectedLocation);
  
    if (entryPhoto) formData.append('entry_photo', entryPhoto);
    if (backstockPhoto) formData.append('backstock_photo', backstockPhoto);
    if (exitPhoto) formData.append('exit_photo', exitPhoto);
    formData.append('comments', comments);
  
    try {
      const response = await axios.post(process.env.REACT_APP_FORM_API_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      alert('Form submitted successfully!');
      setCurrentStep(1); // Return to the start after successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form.');
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div style={{ textAlign: 'center' }}>
            <button onClick={() => handleLanguageSelect('en')} style={{ border: 'none', background: 'none' }}>
              <img src="/img/english-flag.png" alt="English" style={{ width: '100px', height: '60px' }} />
            </button>
            <button onClick={() => handleLanguageSelect('fr')} style={{ border: 'none', background: 'none' }}>
              <img src="/img/french-flag.png" alt="Francais" style={{ width: '100px', height: '60px' }} />
            </button>
          </div>
        );

      case 2:
        return loading ? renderLoading() : <div></div>;

      case 2.1:
        return (
          <div style={{ textAlign: 'center' }}>
            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Enter address" />
            <button onClick={handleAddressSubmit} disabled={loading}>Submit Address</button>
          </div>
        );

      case 2.2:
        // Radius Distance and Unit Input
        return (
          <div style={{ textAlign: 'center' }}>
            <label>{t('Radius')}:</label>
            <input
              type="number"
              min="1"
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
            />
            <br />
            <label>{t('Unit')}:</label>
            <select value={unit} onChange={(e) => setUnit(e.target.value)}>
              <option value="miles">{t('Miles')}</option>
              <option value="kilometers">{t('Kilometers')}</option>
            </select>
            <br />
            <button onClick={() => {
              if (coordinates) {
                performLocationLookup(coordinates.latitude, coordinates.longitude);
              } else {
                alert('Location not set. Please go back and set your location.');
              }
            }}>{t('Next')}</button>
          </div>
        );
          
      case 3: // Combine step 2.2 and 3
      return (
        <div>
          <div style={{ textAlign: 'center' }}>
            <label>{t('Radius')}:</label>
            <input
              type="number"
              min="1"
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
            />
            <br />
            <label>{t('Unit')}:</label>
            <select 
              value={unit} 
              onChange={(e) => setUnit(e.target.value)}>
              <option value="miles">{t('Miles')}</option>
              <option value="kilometers">{t('Kilometers')}</option>
            </select>
            <br />
            <button onClick={handleLookup}>{t('Perform Radius Lookup')}</button>
            <br />
          </div>
          <br />
          {loading ? renderLoading() : (
            <div style={{ textAlign: 'center' }}>
              <HereMap locations={locations} mapCenter={mapCenter} radius={radius} />
              <select
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
                disabled={loading}
              >
                <br />
                <option value="">{t('Select a location')}</option>
                {locations.map((location, index) => (
                  <option key={index} value={location.Name}>
                    {`${location.Name}, ${location.Street}, ${location.City}, ${location.Postcode}`}
                  </option>
                ))}
              </select>
              <button onClick={() => setCurrentStep(4)}>{t('Next')}</button>
            </div>
          )}
        </div>
      );

      case 4:
      return (
        <form onSubmit={handleFormSubmit}>
          <label>{t('Name')}</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          <label>{t('Email')}</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <label>{t('Fridge Serial Number')}</label>
          <input type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
          <label>{t('Entry Photo')}</label>
          <input type="file" accept="image/*" onChange={(e) => setEntryPhoto(e.target.files[0])} />
          <label>{t('Backstock Photo')}</label>
          <input type="file" accept="image/*" onChange={(e) => setBackstockPhoto(e.target.files[0])} />
          <label>{t('Exit Photo')}</label>
          <input type="file" accept="image/*" onChange={(e) => setExitPhoto(e.target.files[0])} />
          <label>{t('Comments')}</label>
          <input type="text" value={comments} onChange={(e) => setComments(e.target.value)} />
          <button type="submit">{t('Submit')}</button>
          {loading && renderLoading()}
        </form>
      );

      default:
        return <div>Invalid step</div>;
    }
  };

  const showBackButton = currentStep > 1; // Adjust based on your logic

  return (
    <div>
      <Header 
        showBackButton={currentStep > 1 && currentStep !== 2.2}
        onBackClick={handleBack} 
        onHomeClick={handleHomeClick}
      />
      <div style={{ marginTop: '100px' }}>
        {renderStep()}
      </div>
    </div>
  );
}

export default App;
