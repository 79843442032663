import React, { useEffect } from 'react';
import locationIcon from './assets/location.svg'; 
import storesIcon from './assets/stores.svg';

// Function to calculate the zoom level based on the radius
function calculateZoomForRadius(radius) {
  // Placeholder logic - adjust as needed
  const baseZoomLevel = 14; 
  return baseZoomLevel - Math.log2(radius); 
}

// Function to add markers to the map
function addMarkersToMap(map, locations, ui, userLocation) {
  const group = new H.map.Group();
  let currentBubble = null;
  const iconSize = { w: 48, h: 48 };
  const userIcon = new H.map.Icon(locationIcon, { size: iconSize });
  const storeIcon = new H.map.Icon(storesIcon, { size: iconSize });

  // Add a marker for user's current location if valid
  if (userLocation && !isNaN(userLocation.lat) && !isNaN(userLocation.lng)) {
    const userMarker = new H.map.Marker(userLocation, { icon: userIcon });
    group.addObject(userMarker);
  }

  // Add markers for each location
  locations.forEach((location) => {
    if (!isNaN(location.Latitude) && !isNaN(location.Longitude)) {
      const marker = new H.map.Marker({
        lat: location.Latitude,
        lng: location.Longitude,
        icon: storeIcon
      });
      marker.setData(`<div><strong>${location.Name}</strong><p>${location.Street}, ${location.City}, ${location.Postcode}</p></div>`);
      group.addObject(marker);
    }
  });

  map.addObject(group);

  group.addEventListener('tap', (evt) => {
    if (currentBubble) {
      ui.removeBubble(currentBubble);
    }
    currentBubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
      content: evt.target.getData(),
    });
    ui.addBubble(currentBubble);
  });
}

// Main HereMap component
function HereMap({ locations, mapCenter, radius = 5 }) { // Default radius is set to 5 miles
  useEffect(() => {
    if (mapCenter) {
      const platform = new H.service.Platform({
        apikey: 'QYs6_bhzT2ToKrfir9hE7LNm4W1kNg8iE53dXGUfRMc' // Replace with your API key
      });
      const defaultLayers = platform.createDefaultLayers();
      const mapContainer = document.getElementById('map-container');

      if (mapContainer) {
        const zoomLevel = calculateZoomForRadius(radius);
        const map = new H.Map(mapContainer, defaultLayers.vector.normal.map, {
          center: mapCenter,
          zoom: zoomLevel,
        });

        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        const ui = H.ui.UI.createDefault(map, defaultLayers);

        addMarkersToMap(map, locations, ui, mapCenter);

        return () => {
          map.dispose();
        };
      }
    }
  }, [locations, mapCenter, radius]);

  return (
    <div style={{ height: '100%' }}>
      <div id="map-container" style={{ width: '100%', height: '350px' }}></div>
    </div>
  );
}

export default HereMap;
