import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './main.css';
import App from './app';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';

// Importing translations
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';

// Initialize i18next
i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // default language
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
  },
});

// Render the root component
createRoot(document.getElementById('root')).render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);

// Register the service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/serviceWorker.js').then(registration => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, err => {
      console.log('ServiceWorker registration failed: ', err);
    }).catch(err => {
      console.log(err);
    });
  });
}
