// src/components/Header.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo192.png';
import backIcon from '../assets/back-icon.svg';
import homeIcon from '../assets/home-icon.svg'; 

const Header = ({ onBackClick }) => {
  const { t } = useTranslation();

  const iconButtonStyle = {
    border: 'none',
    background: 'none',
    height: '55px',
    marginLeft: '5px' // Space between icons
  };

  const iconStyle = {
    height: '100%' // Adjust as necessary
  };

  return (
    <header style={headerStyle}>
      <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <img src={logo} alt="Logo" style={{ height: '45px', marginRight: '10px' }} />
        <h1 style={{ color: 'white', margin: 0 }}>{t('Visits')}</h1>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button onClick={() => window.location.reload()} style={iconButtonStyle}>
          <img src={homeIcon} alt="Home" style={iconStyle} />
        </button>
        <button onClick={onBackClick} style={iconButtonStyle}>
          <img src={backIcon} alt="Back" style={iconStyle} />
        </button>
      </div>
    </header>
  );
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#012141',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 1000,
  padding: '10px'
};

export default Header;
